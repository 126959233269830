<template>
  <!-- 改签下单 -->
  <div class="ticket">
    <!-- 第一部分 -->
    <Head :aircraftTicket="aircraftTicket[0]" />
    <PeoList :updateResult.sync="result" :seat="[coupons[chosenCoupon]]" :compliant="compliant"
      :explanationText="explanationText" :showStandard="config_changeControl" @explanFinish="explanFinish" />

    <div class="gap"></div>

    <!-- 第二部分 -->
    <van-panel title="选票">
      <van-coupon-cell class="chooseTicketBk" v-if="coupons[chosenCoupon]"
        :title="coupons[chosenCoupon].seatClass+'（￥'+coupons[chosenCoupon].value+'）'"
        :value="'余票：'+(coupons[chosenCoupon].number>0?coupons[chosenCoupon].number:'充足')" @click="showList = true;" />
      <van-collapse v-model="activeNames">
        <van-collapse-item title="退改规则" name="1">
          <div class="rule">
            <van-row gutter="10">
              <van-col class="thead" span="8">时间点</van-col>
              <van-col class="thead" span="5">退票费</van-col>
              <van-col class="thead" span="6">同舱改期费</van-col>
              <van-col class="thead" span="5">签转</van-col>
            </van-row>
            <van-row gutter="10" v-for="(item,i) in ruleList.rulePointList" :key="i">
              <van-col class="van-cell__label" span="8">{{item.content}}</van-col>
              <van-col class="van-cell__label" span="5">￥{{item.refundFee}}/人</van-col>
              <van-col class="van-cell__label" span="6">￥{{item.modifyFee}}/人</van-col>
              <van-col class="van-cell__label" span="5">{{item.modifyStipulate}}</van-col>
            </van-row>
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-panel>
    <el-tooltip class="item" effect="dark" v-if="ruleList.baggage" :content="ruleList.baggage" placement="top">
      <van-notice-bar left-icon="volume-o" :text="'托运规则：'+ruleList.baggage" />
    </el-tooltip>

    <!-- 第三部分 -->

    <!-- 座次类型弹出层 -->
    <van-popup v-model="showList" round position="bottom" class="pick-ticket">
      <AircraftTicket :coupons="coupons" :chosen-coupon="chosenCoupon" :showList="showList" @change="onChange" />
      <!-- <van-coupon-list enabled-title="有票" disabled-title="无票" :coupons="coupons" :chosen-coupon="chosenCoupon" :disabled-coupons="disabledCoupons" :show-exchange-bar="false" :close-button-text="'确定'" @change="onChange" /> -->
    </van-popup>

    <div class="gap"></div>

    <!-- 第三部分 -->
    <BodyList />
    <div class="bottom-gap"></div>

    <!-- 第四部分 -->
    <van-submit-bar button-type="info" tip-icon="info-o" tip="实际支付价格以申请改签后所需支付的价格为准。" id="price-bar" button-text="申请改签" @submit="onSubmit">
    </van-submit-bar>

    <!-- 遮罩层 -->
    <LoadingAnimate v-if="wrapper" :text="'占座中'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkSeat } from "@/services/user";
import {
  createChangeOrder,
  updataTicketDom,
  checkPrice
} from "@/services/aircraft";
import {
  AircraftServiceProvider,
  REASONS,
  GoWhere,
  ChangeControl
} from "@/common/config";
import Logo from "@/components/Logo";
import Head from "./components/InfoHead";
import PeoList from "./components/PeoList";
import BodyList from "@/components/InfoList";
import LoadingCircle from "@/components/LoadingCircle";
import AircraftTicket from "@/components/AircraftTicket";
import LoadingAnimate from "@/components/LoadingAnimate";
import {
  getFlightModifyAndRefundRules,
  getFlightOrderRefundInfo
} from "@/api/aircraft";

export default {
  components: {
    LoadingCircle,
    LoadingAnimate,
    Head,
    BodyList,
    PeoList,
    Logo,
    AircraftTicket
  },
  computed: {
    ...mapGetters(["aircraftTicket", "travelInfo", "aircraftOrderChange"]),
    // 改签管控 - 配置
    config_changeControl() {
      return !(ChangeControl[0] == "all" && ChangeControl[1] == "all");
    }
  },
  mounted() {
    this.aircraftTicket[0].flightNo;
  },
  activated() {
    window.setTitle(this.aircraftTicket[0].flightNo);
    this.getSeatList();
    this.fetchRule();
    this.compliant = false;
    this.wrapper = false;
    this.showList = false;
  },
  data() {
    return {
      config_gowhere: GoWhere, // 下单成功跳转到哪儿
      allCorp: AircraftServiceProvider,
      showPicker: false,
      result: [], // 已选择的人员列表
      chosenCoupon: 0, // 可购列表选中的下标
      coupons: [], // 可购座位列表
      disabledCoupons: [], // 不可购列表
      ruleList: {}, // 退改签规则
      showList: false, // 选座弹出层
      compliant: false, // 超标填表开关
      wrapper: false, // 遮罩层
      explanation: REASONS[0], // 申请信息
      explanation1: "",
      explanationText: [],
      index: null, // 编辑框位置
      activeNames: [], // 折叠面板
      show: false,
      checked: true
    };
  },
  methods: {
    // 超标填表成功
    explanFinish(explanation, explanation1) {
      this.compliant = false;
      if (explanation && explanation1) {
        this.explanation = explanation;
        this.explanation1 = explanation1;
        this.grabSeat();
      }
    },
    // 生成两种座位列表
    getSeatList() {
      this.coupons = [];
      this.disabledCoupons = [];
      let minpeo = { stand: { allowBookHighest: 999999 } };
      this.aircraftOrderChange.peoList.forEach(e => {
        if (parseInt(e.stand.allowBookHighest) < minpeo.stand.allowBookHighest)
          minpeo = e;
      });
      this.aircraftTicket[0].flightSeat.forEach(element => {
        element.description = checkSeat(minpeo, element, "aircraft");
        element.corp = this.aircraftOrderChange.order.corp;
        element.name =
          "余票：" + (element.number > 0 ? element.number : "充足");
        element.value = element.price.seatPrice;
        element.number != 0
          ? this.coupons.push(element)
          : this.disabledCoupons.push(element);
      });
    },
    // 选票的钩子
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index >= 0 ? index : this.chosenCoupon;
      this.fetchRule();
    },
    // 拉取退改签规则
    fetchRule() {
      getFlightModifyAndRefundRules({
        bookInfo: this.coupons[this.chosenCoupon].bookInfoStr,
        corp: this.aircraftTicket[0].corp
      }).then(response => {
        this.ruleList = response.data;
      });
    },
    // 点击提交按钮，该方法仅做一些简单的验证，之后还需要调用合规检查，最后才可以提交占座
    onSubmit: async function() {
      this.explanationText = [];
      if (this.result.length <= 0) return this.$toast.fail("尚未选择人员");
      // 人员信息全吗？
      for (let i = 0; i < this.result.length; i++) {
        let element = this.result[i];
        if (
          !Object.prototype.hasOwnProperty.call(element, 'tel')||
          !Object.prototype.hasOwnProperty.call(element, 'birthday')||
          !Object.prototype.hasOwnProperty.call(element, 'gender')
        )
          return this.$toast.fail("人员信息不完整");
      }
      // 超标没？ 【“all”，“all”】 不管
      if (this.config_changeControl) {
        this.result.forEach(element => {
          if (
            !checkSeat(element, this.coupons[this.chosenCoupon], "aircraft")
          ) {
            this.explanationText.push(element);
          }
        });
      }
      if (this.explanationText.length > 0) return (this.compliant = true);
      // 变价没？
      let flightInfo = await checkPrice({
        flightInfo: [this.aircraftTicket[0]]
      });
      if (flightInfo.check) {
        // 如果变价
        this.$dialog
          .confirm({
            title: "提示",
            message:
              "您购买的机票发生变价，当前价格" +
              flightInfo.flight[0].flightSeat[0].price.seatPrice +
              "，是否继续支付？"
          })
          .then(() => {
            this.grabSeat(flightInfo.flight[0]); // 下单
          });
      } else this.grabSeat(flightInfo.flight[0]);
    },
    // 抢座
    async grabSeat(flightInfo) {
      this.wrapper = true;
      let req = {
        flightInfo: flightInfo,
        applyNo: this.travelInfo.appId,
        orderNo: this.aircraftOrderChange.order.orderSerialNo,
        flightPassengerItem: this.result,
        corp: this.aircraftOrderChange.order.corp
      };
      let ticket1 = await createChangeOrder(req, this.$route.name);
      this.wrapper = false;
      if (ticket1) {
        switch (this.config_gowhere) {
          case "detail": // 转到详情
            this.$router.push({
              path: "/paymentAircraftChange",
              query: {
                corp: ticket1.corp,
                orderNo: ticket1.orderSerialNo,
                outOrderNo: ticket1.outOrderNo
              }
            });
            break;
          default:
            // 默认转到订单列表
            this.$router.push({ path: "/plan" });
            break;
        }
      }
    }
  }
};
</script>

<style lang="less" src="@/styles/orderCommon.less"></style>
<style lang="less">
@import "../../styles/theme.less";
.rule {
  .van-panel__header {
    padding: 5px 16px;
  }
}
.wrapper .wrapper {
  top: 74% !important;
}
.rule {
  padding: 0 16px;
  padding-bottom: 10px;
  .thead {
    font-size: @font-size-md;
    color: @gray-7;
  }
}
.search {
  font-size: @font-size-lg;
}
.content {
  padding: 16px 16px 90px;
}

.text {
  transition: all 2s;
  font-size: @font-size-md;
  color: @gray-7;
  width: 97%;
}
.product {
  color: @blue;
  font-size: @font-size-lg;
}
.bigcheck {
  position: relative;
  top: -10px;
}
.fanxian {
  font-size: @font-size-sm;
  color: @gray-5;
}
.tuigai {
  width: 100%;
}
.custom-title {
  padding-right: 4px;
}
</style>
